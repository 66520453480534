@keyframes gradient-animation {
  0% {
    background-position: 0% 0%;
    /* Start at top left */
  }

  25% {
    background-position: 50% 0%;
    /* Move to mid top */
  }

  50% {
    background-position: 100% 0%;
    /* Move to bottom left */
  }

  75% {
    background-position: 50% 100%;
    /* Move to mid bottom */
  }

  100% {
    background-position: 0% 100%;
    /* End at bottom left */
  }
}

.bg-animated-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 20s ease-in-out reverse;
  background-image: linear-gradient(to bottom,
      #f3f8f4,
      /* Start color */
      #c5cae7,
      /* Intermediate color */
      #c5d4e7,
      /* Mid color */
      #d4ebec,
      /* Intermediate color */
      #f0e0e4
      /* End color */
    );
  background-position: 0% 0%;
  background-repeat: no-repeat;
}

.hover-link:hover {
  color: black;
}

/* Optional: Ensure smooth transition for background and box-shadow */
.hover-effect {
  transition: box-shadow 0.3s ease;
}

/* Hover effect */
.hover-effect:hover {
  /* background: linear-gradient(45deg, #ff416c, #ff4b2b) !important; */
  background: linear-gradient(90deg, #e748b1 0%, #b947d9 33%, #7752e7 67%, #4567ed 100%) !important;

  /* Example gradient on hover */
  /* box-shadow: 8px 8px 5px rgba(224, 224, 224, 0.3) !important; */
  /* Deeper shadow on hover */
}

.mycode {
  /* background: linear-gradient(45deg, #ff416c, #ff4b2b) !important; */
  background: linear-gradient(90deg, #e748b1 0%, #b947d9 33%, #7752e7 67%, #4567ed 100%) !important;
  color: white !important;  
  /* Example gradient on hover */
  /* box-shadow: 8px 8px 5px rgba(224, 224, 224, 0.3) !important; */
  /* box-shadow: 0px 0px 0px rgba(224, 224, 224, 0.3) !important; */

  /* Deeper shadow on hover */
}

.mycode:hover {
  background: none !important;
  /* box-shadow: 8px 8px 5px rgba(224, 224, 224, 0.3) !important; */
}

/* <div className="main-nav-sub full-wrapper dark dark-mode transparent" style={{ background: 'rgba(0, 0, 0, 0.98) !important' }}> */
